
import { apiPackageDel, apiPackageLists, apiPackageGets, apiPackageDiscount, apiGoodsRediscount } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import PopoverInput from '@/components/popover-input.vue'
@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData,
        PopoverInput
    }
})
export default class Supplier extends Vue {
    queryObj = {
        name: ''
    }
    pager = new RequestPaging()
    apiPackageLists = apiPackageLists
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiPackageLists,
            params: {
                ...this.queryObj
            }
        })
    }
    handleReset() {
        this.queryObj = {
            name: ''
        }
        this.getList()
    }
	
    handleRediscount(val: string, id: number) {
        apiGoodsRediscount({
            id,
            discount: val
        }).then(() => {
            this.getList()
        })
    }

    handleDiscount(id: number) {
        apiPackageDiscount(id).then(() => {
            this.getList()
        })
    }
	
    created() {
        this.getList()
    }
}
